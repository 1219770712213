import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'confirmation-modal',
  templateUrl: 'confirmation-modal.component.html',
  styleUrls: ['confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() config: any;
  tint1: string;
  tint2: string;
  highlightColor: string;
  textColor: string;

  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef, private _storageService: LocalStorageService) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    this.textColor = this._storageService.getItem('textColor', 'localStorage') ? '#' + this._storageService.getItem('textColor', 'localStorage') : '#ffffff';
    this.tint1 = this._storageService.getItem('primaryTint1', 'localStorage') ? '#'+this._storageService.getItem('primaryTint1', 'localStorage') : '#2d333c';
    this.tint2 = this._storageService.getItem('primaryTint2', 'localStorage') ? '#' + this._storageService.getItem('primaryTint2', 'localStorage') : '#444a58';
    this.highlightColor = this._storageService.getItem('highlightColor', 'localStorage') ? '#' + this._storageService.getItem('highlightColor', 'localStorage') : '#df5844';
  }

  cancelChanges() {
    this.onClose.next(true);
  }
  dontCancel() {
    this.bsModalRef.hide();
    this.onClose.next(false);
  }
}
