import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule } from './layouts/layouts.module';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        SplashScreenComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutsModule,
        HttpClientModule,
        CoreModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        })
    ],
    providers: [DatePipe,
        { provide: 'googleChartsVersion', useValue: '46' },
        { provide: 'mapsApiKey', useValue: environment.mapApiKey }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
