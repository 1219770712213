import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SelectorLayoutComponent } from './selector-layout/selector-layout.component';
import { LayoutsRoutingModule } from './layouts-routing.module';
// import { TopLeftLogoContainerComponent } from './top-left-logo-container/top-left-logo-container.component';
import { CopyRightContainerComponent } from './copy-right-container/copy-right-container.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        MainLayoutComponent,
        SelectorLayoutComponent,
        CopyRightContainerComponent
    ],
    imports: [
        CommonModule,
        LayoutsRoutingModule,
        SharedModule
    ]
})
export class LayoutsModule { }
