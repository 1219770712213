import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MortarService } from '../../../pages/stone-creation/service/mortar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mortar-color-picker',
    templateUrl: './mortar-color-picker.component.html',
    styleUrls: ['./mortar-color-picker.component.scss']
})
export class MortarColorPickerComponent implements OnInit, OnDestroy {

    @Input() hideTitle;
    @Input() id;
    @Output() selectedMortar = new EventEmitter<object>();

    color: string;
    active = false;
    pickedColorSub: Subscription;

    constructor( private _mortarService: MortarService ) { }

    ngOnInit(): void {
        this.pickedColorSub = this._mortarService.pickedMortarColor.subscribe(res => {
            if (res) {
                this.color = '#' + res;
            }
        });
    }

    ngOnDestroy() {
        this.pickedColorSub.unsubscribe();
    }

    select(event) {
        if (event) {
            this._mortarService.pickMortarColor(event.substr(1));
            this.selectedMortar.emit({
                id: 0,
                name: event.substr(1),
                hex_value: event.substr(1)
            });
        }
    }

}
