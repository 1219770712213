import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

    message = '';
    show = false;
    alertSub: Subscription;
    textColor: string;
    shade1: string;

    constructor(private _alertService: AlertService,
                private _localStorage: LocalStorageService) { }

    ngOnInit(): void {
        this.textColor = this._localStorage.getItem('textColor', 'localStorage') ? '#' + this._localStorage.getItem('textColor', 'localStorage') : '#ffffff';
        this.shade1 = this._localStorage.getItem('primaryShade1', 'localStorage') ? '#' + this._localStorage.getItem('primaryShade1', 'localStorage') : '#20262e';

        this.alertSub = this._alertService.showAlert.subscribe(res => {
            this.show = res;
            if (res) {
                this.message = this._alertService.alertMessage;
            }
        });
    }

    ngOnDestroy() {
        this.alertSub.unsubscribe();
    }

}
