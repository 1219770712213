import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IResponse } from '../models/response';
import { LoaderService } from '../services/loader.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})

export class BaseAPIClass {
    private baseUrl: string = environment.apiUrl;

    constructor(protected httpClient: HttpClient,
                private _localStorageService: LocalStorageService,
                private _loaderService: LoaderService) { }
    /**
     * Function to create http headers.
     */
    private _setHeaders(path): HttpHeaders {
        const ACCESS_TOKEN = this._localStorageService.getItem('access', 'localStorage');
        let headersConfig = path.includes('login') ? {
            'Content-Type': 'application/json',
            Authorization: 'Token dffd3fe4685b3d03d16d495c5b3f29cfd939fbec'
        } : {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + ACCESS_TOKEN
        };
        return new HttpHeaders(headersConfig);
    }

    private _setHeadersWithoutToken(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json'
        };
        return new HttpHeaders(headersConfig);
    }

    /**
     * To perform get api call.
     * @param path
     * @param filterObject [Pass filters(query params) as key value pairs];
     */
    get(path: string, filterObject?: any, showLoader = true): Observable<any> {
        if (showLoader) {
            this._loaderService.showLoaderSubject.next(true);
        }
        let queryString = '';
        if (filterObject) {
            const fitlerKeys: any[] = Object.keys(filterObject);
            if (fitlerKeys.length > 0) {
                queryString = '?';
            }
            fitlerKeys.forEach((key: any, index) => {
                if (filterObject[key] !== undefined && filterObject[key] !== null) {
                    if (filterObject[key].toString().length) {
                        queryString += `${key}=${filterObject[key]}&`;
                    }
                }
            });
            if (
                fitlerKeys.length > 0 &&
                queryString[queryString.length - 1] === '&'
            ) {
                queryString = queryString.slice(0, -1);
            }
        }
        return this.httpClient.get(`${this.baseUrl}${path}${queryString}`, { headers: this._setHeaders(path) }).pipe(
            map((body: IResponse) => {
                this._loaderService.showLoaderSubject.next(false);
                return body;
            }),
            catchError((err) => of(err))
        );
    }

    getFromExternal(path: string, filterObject?: any, showLoader = true): Observable<any> {
        if (showLoader) {
            this._loaderService.showLoaderSubject.next(true);
        }
        let queryString = '';
        if (filterObject) {
            const fitlerKeys: any[] = Object.keys(filterObject);
            if (fitlerKeys.length > 0) {
                queryString = '?';
            }
            fitlerKeys.forEach((key: any, index) => {
                if (filterObject[key] !== undefined && filterObject[key] !== null) {
                    if (filterObject[key].toString().length) {
                        queryString += `${key}=${filterObject[key]}&`;
                    }
                }
            });
            if (
                fitlerKeys.length > 0 &&
                queryString[queryString.length - 1] === '&'
            ) {
                queryString = queryString.slice(0, -1);
            }
        }
        return this.httpClient.get(`${path}${queryString}`).pipe(
            map((body: IResponse) => {
                this._loaderService.showLoaderSubject.next(false);
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }

    /**
     * To perform post api call.
     * @param path
     * @param payload
     */
    post(path: string, payload?: any, showLoader = true): Observable<any> {
      if (showLoader) {
        this._loaderService.showLoaderSubject.next(true);
      }
      if (path) {

          return this.httpClient.post(`${this.baseUrl}${path}`, payload, { headers: this._setHeaders(path) }).pipe(
            map((body: any) => {
              this._loaderService.showLoaderSubject.next(false);
                return body;
            }),
            catchError((err) => of({message: err}))
        );
      } else {
        return this.httpClient.post(`${this.baseUrl}${path}`, payload, { headers: this._setHeaders(path) }).pipe(
          map((body: any) => {
            this._loaderService.showLoaderSubject.next(false);
              return body;
          }),
          catchError((err) => of({message:err}))
      );
      }
    }
    _postFormData(path: string, payload: any, showLoader = true): Observable<any>  {
      if (showLoader) {
        this._loaderService.showLoaderSubject.next(true);
      }
      const ACCESS_TOKEN = this._localStorageService.getItem('access', 'localStorage');
      const httpOptions = {
        headers: new HttpHeaders({
         "Authorization": 'Bearer ' + ACCESS_TOKEN
        })
      };
      return this.httpClient.post(`${this.baseUrl}${path}`, payload, httpOptions).pipe(
        map((body: any) => {
          this._loaderService.showLoaderSubject.next(false);
            return body;
        }),
        catchError((err) => of({message:err})));
    }
    postFormData(path: string, payload: any, showLoader = true): Observable<any> {
      if (showLoader) {
        this._loaderService.showLoaderSubject.next(true);
      }
        return this.httpClient.post(`${this.baseUrl}${path}`, payload, { headers: this._setHeaders(path) }).pipe(
            map((body: any) => {
              this._loaderService.showLoaderSubject.next(false);
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }
    /**
     * To perform put api call.
     * @param path
     * @param payload
     */
    put(path: string, payload: any): Observable<any> {
        //   this._loaderService.showLoader();
        return this.httpClient.put(`${this.baseUrl}${path}`, payload, { headers: this._setHeaders(path) }).pipe(
            map((body: any) => {
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }
    /**
     * To perform delete api call.
     * @param path
     */
    delete(path: string): Observable<any> {
        //  this._loaderService.showLoader();
        return this.httpClient.delete(`${this.baseUrl}${path}`, { headers: this._setHeaders(path) }).pipe(
            map((body: any) => {
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }
    /**
     * To perform patch api call.
     * @param path
     * @param payload
     */
    patch(path: string, payload: any): Observable<any> {
        //   this._loaderService.showLoader();
        return this.httpClient.patch(`${this.baseUrl}${path}`, payload, { headers: this._setHeaders(path) }).pipe(
            map((body: any) => {
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }
    /**
     * To handle errors.
     * @param error
     */
    private _handleErrors(error: Response): any {
        console.log(error);
        let response: any = {};
        try {
            response = error.json();
        } catch (ex) {
            try {
                response['errors'] = error;
            } catch (ex) {
                response['errors'] = error.toString();
            }
        }
        switch (error.status) {
            case 401:
                response = {
                    errors: 'Oops, Unauthorized user'
                };
                break;
            case 404:
                response = {
                    errors: 'Oops, it looks like that resource was not found'
                };
                break;
            case 500:
                response = {
                    errors: 'Oops, Something went wrong please try again later.'
                };
                break;
            default:
                response = {
                    errors: 'Oops, Something went wrong.'
                };
                break;
        }
        if (this._loaderService) {
            this._loaderService.showLoaderSubject.next(false);
        }
        return response.errors;
    }

    getData(path: string, showLoader = true): Observable<any> {
        if (showLoader) {
            this._loaderService.showLoaderSubject.next(true);
        }
        return this.httpClient.get(`${this.baseUrl}${path}`, { headers: this._setHeadersWithoutToken() }).pipe(
            map((body: IResponse) => {
                this._loaderService.showLoaderSubject.next(false);
                return body;
            }),
            catchError((err) => this._handleErrors(err))
        );
    }

}
