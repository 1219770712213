import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from './services/local-storage.service';
import { RouteReuseStrategy } from '@angular/router';

import { RouteReusableStrategy } from './classes/route-reusable-strategy';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        LocalStorageService,
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class CoreModule { }
