import { Component, OnInit } from '@angular/core';
import { animate, style, group, query, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    animations: [
        trigger('splashAnimate', [
            transition(':enter', [
                group([
                    query('img', [
                        style({ opacity: 0 }),
                        group([
                            animate('1200ms 1s ease', style({ opacity: 1, transform: 'translateY(20px)' })),
                            animate('1200ms 2s ease', style({ transform: 'translateY(0px)' })),
                        ])
                    ]),
                    query('.design', [
                        style({ opacity: 0, transform: 'translateY(-5px)' }),
                        group([
                            animate('1s 2.5s ease', style({ opacity: 1 })),
                            animate('1s 2.5s ease', style({ transform: 'translateY(0px)' }))
                        ])
                    ], {optional: true })
                ])

            ]),
            transition(':leave', [
                group([
                    query('img', [
                        style({ opacity: 1 }),
                        animate('1s 0s ease', style({ opacity: 0 }))
                    ]),
                    query('.design', [
                        style({ opacity: 1 }),
                        animate('1s 0s ease', style({ opacity: 0 }))
                    ], {optional: true })
                ])
            ])
        ])
    ]


})
export class SplashScreenComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }

}
