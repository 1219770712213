import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PdfGenerationService } from '../../../services/pdf-generation.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-saved-panels',
    templateUrl: './saved-panels.component.html',
    styleUrls: ['./saved-panels.component.scss']
})
export class SavedPanelsComponent implements OnInit, OnDestroy {

    @Input() panel;

    @ViewChild('panelPage') page: ElementRef;

    @Output() pdfGenerated = new EventEmitter<object>();

    printSub: Subscription;
    userData: any;
    logoUrl: any;
    pdfSrc: string;

    constructor(private _pdfService: PdfGenerationService,
                private renderer: Renderer2,
                private authenticationService: AuthenticationService,
                private _storageService: LocalStorageService) { }

    ngOnInit(): void {
        this.pdfSrc = environment.siteUrl;
        this.printSub = this._pdfService.printClicked.subscribe(res => {
            if (res) {
                this.generatePdf(true);
            }
        });
        this.authenticationService.userData.subscribe(res => {
         if(res) {this.userData = res;
            this.logoUrl = this.userData.logo ? this.userData.logo : '../../../assets/images/Stonelogo@s.png';
}
        });
    }

    ngOnDestroy() {
        this.printSub.unsubscribe();
        this._pdfService.printClicked.next(null);
    }

    getBG() {
        if (this.panel.selectedMortar) {
            return {
                backgroundColor: '#' + this.panel.selectedMortar.hex_value
            };
        }
        return {
            // tslint:disable-next-line:max-line-length
            backgroundColor: this._storageService.getItem('primaryShade1', 'localStorage') ? this._storageService.getItem('primaryShade1', 'localStorage') : '#20262e'
        };
    }

    generatePdf(print) {
        if (this.panel.selected) {
            const data = this.page.nativeElement;
            if (this.isMobile()) {
                this.renderer.setStyle(data, 'margin-top', '210px');
            }
            html2canvas(data, {
                scale: 3,
                allowTaint: true,
                useCORS: true,
                logging: false,
                removeContainer: true
            }).then(canvas => {
                // Few necessary setting options
                const contentDataURL = canvas.toDataURL('image/png');
                this.panel.pdfContent = {
                    contentDataURL,
                    height: canvas.height,
                    width: canvas.width
                };
                if (this.isMobile()) {
                    this.renderer.removeStyle(data, 'margin-top');
                }
                this.pdfGenerated.emit({
                    print
                });
            });
        }
    }

    getSliderImageSrc(active: boolean, value) {
        if (active) {
            return '../../../../../assets/images/cost-sliders-active/' + Math.ceil(value) + '.png';
        } else {
            return '../../../../../assets/images/cost-sliders-inactive/' + Math.ceil(value) + '.png';
        }
    }

    isMobile() {
        return (window.innerWidth <= 767) ? true : false;
    }
}
