import { Injectable } from '@angular/core';
import { BaseAPIClass } from '../../../core/classes/base-api-class';
import { BehaviorSubject } from 'rxjs';
import { IResponse } from '../../../core/models/response';
import { map } from 'rxjs/operators';
import { IMortar } from '../models/mortar';

@Injectable()
export class MortarService {

    private _mortar = new BehaviorSubject<IMortar[]>([]);

    selectedMortar = new BehaviorSubject<IMortar>(null);
    pickedMortarColor = new BehaviorSubject<string>('');

    constructor(private _http: BaseAPIClass) { }

    get mortars() {
        if ((!this._mortar.value.length) || (this._mortar.value == null)) {
            return this._http.get('sample-panel/mortars/')
                .pipe(map((response: IResponse) => {
                    if (response.status === 200) {
                        this._mortar.next(response.data);
                        return this._mortar.value;
                    }
                }));
        }
        return this._mortar;
    }

    selectMortar(id: number) {
        if (id === this.selectedMortar.value?.id) {
            this.selectedMortar.next(null);
        } else {
            const index = this._mortar.value.findIndex(item => item.id === id);
            this.selectMortarByIndex(index);
            return index;
        }

    }
    selectMortarByIndex(index: number) {
        this.selectedMortar.next(this._mortar.value[index]);
    }

    pickMortarColor(color) {
        this.pickedMortarColor.next(color);
        this.selectedMortar.next({
            id: 0,
            name: color,
            hex_value: color
        });
        return -1;
    }
}
