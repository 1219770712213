import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AlertService {

    showAlert = new BehaviorSubject<boolean>(false);
    alertMessage = '';
    alertInterval;

    constructor() { }

    alert(msg: string) {
        clearInterval(this.alertInterval);
        this.alertMessage = msg;
        this.showAlert.next(true);
        this.alertInterval = setInterval(() => {
            this.showAlert.next(false);
        }, 3000);
    }
}
