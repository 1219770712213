import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ElementRef } from '@angular/core';
import { LoaderService } from '../app/core/services/loader.service';
import { LocalStorageService } from '../app/core/services/local-storage.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    showSplash = true;
    showLoader = true;
    loaderSub: Subscription;
    sessionSub: Subscription;

    constructor(private _loaderService: LoaderService,
                private _storageService: LocalStorageService,
                private elementRef: ElementRef) { }

    ngAfterViewInit() {
        this.elementRef.nativeElement.ownerDocument
            .body.style.backgroundColor = this._storageService.getItem('primaryColor', 'localStorage') ? '#' + this._storageService.getItem('primaryColor', 'localStorage') : '#262c34';
    }
    ngOnInit() {
        setTimeout(() => {
            this.showSplash = false;
        }, 4000);
        this.loaderSub = this._loaderService.showLoaderSubject.subscribe(res => {
            setTimeout(() => {
                this.showLoader = res;
            });
        });
        this.sessionSub = this._storageService.sessionWrite.subscribe(res => {
            if (res) {
                this._storageService.setLastSaveTime('lastSaveTime', Date.now());
            }
        });
        this.compareTime();
    }

    ngOnDestroy() {
        this.loaderSub.unsubscribe();
        this.sessionSub.unsubscribe();
    }

    compareTime() {
        // 12 hours = 43200 seconds
        const difference = Date.now() - this._storageService.getItem('lastSaveTime');
        const hours = difference / 1000 / 60 / 60;
        // let minutes = difference / 1000 / 60;
        if (hours >= 12) {
            this._storageService.clearAll();
        }
    }

    // @HostListener('window:orientationchange', ['$event'])
    //     onOrientationChange(event) {
    //        location.reload();
    //     }
}
