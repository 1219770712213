import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ISlideChange } from './carouselModels';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProjectInspirationService } from 'src/app/pages/project-inspiration/service/project-inspiration.service';
import { ConversionService } from 'src/app/core/services/conversion.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [
                style({ opacity: 0, transform: 'scale(0.5)' }),
                animate(
                    '400ms cubic-bezier(0.785, 0.135, 0.15,0.86)',
                    style({ opacity: 1, transform: 'scale(1)' }))
            ]),
            transition('* => void', [
                animate(
                    '400ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
                    style({ opacity: 0, transform: 'scale(0.5)' }))
            ])
        ])
    ]
})
export class CarouselComponent implements OnInit, OnChanges, OnDestroy {

    @Input() slides: any = [];
    @Input() imageField;
    @Input()
    set slideChange(obj: ISlideChange) {
        if (obj.direction === 'prev') {
            this.onPreviousClick();
        } else if (obj.direction === 'next') {
            this.onNextClick();
        }
    }

    @Input() currentSlide = 0;

    isLoading = true;
    imageList: any = {};
    pjtDetailsHeight = null;
    textColor: string;
    rgba: string;
    shade2: string;

    constructor(private _pInspiration: ProjectInspirationService,
                private _conversionService: ConversionService,
                private _storageService: LocalStorageService) { }

    ngOnInit(): void {
       this.isLoading = true;
       this.textColor = this._storageService.getItem('textColor', 'localStorage') ? '#' + this._storageService.getItem('textColor', 'localStorage') : '#ffffff';
       this.shade2 = this._storageService.getItem('primaryShade2', 'localStorage') ? '#'+this._storageService.getItem('primaryShade2', 'localStorage') : '#111417';
       this.rgba = this._conversionService.hexToRgb(this.shade2);
       document.documentElement.style.setProperty('--enlarge-bg-color', 'rgba(' + this.rgba + ',0.75)');
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'slides': {
                        this.isLoading = true;
                        this.preloadImages();
                    }
                }
            }
        }
    }

    onPreviousClick() {
        this.isLoading = true;
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    }

    onNextClick() {
        this.isLoading = true;
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.length ? 0 : next;
    }

    preloadImages() {
        if (this.slides) {
            this.slides.forEach(slide => {
                if (!this.imageList[slide.id]) {
                    this.imageList[slide.id] = new Image();
                    this.imageList.src = slide[this.imageField];
                } else {
                    this.isLoading = false;
                }
            });
        }
    }

    openImageOverlay() {
        const overlay = document.getElementById('image-modal');
        const overlayContent = document.getElementById('overlay-content');
        overlayContent.setAttribute('src', this.slides[this.currentSlide][this.imageField]);
        overlay.style.display = 'flex';
        const watermarkDiv = document.getElementById('watermarkDiv');
        (this.slides[this.currentSlide]?.owner != 'stonwerk') ?
            watermarkDiv.style.display = 'none' :
            watermarkDiv.style.removeProperty('display');
        this._pInspiration.projectInspirationFullScreen.next(true);
    }

    closeImageOverlay() {
        document.getElementById('image-modal').style.display = 'none';
        this._pInspiration.projectInspirationFullScreen.next(false);
    }

    hideLoader(index) {
        this.setHeightAndWidth(index);
        this.isLoading = false;
    }

    setHeightAndWidth(index) {
        const ELEMENT = document.getElementById('image_' + index);
        if (ELEMENT) {
            const HEIGHT = ELEMENT.clientHeight;
            const WIDTH = ELEMENT.clientWidth;
            const PARENT = document.getElementById('imageWrapper');
            if (HEIGHT > WIDTH) {
                PARENT.classList.add('h-100');
                const WATERMARK_DIV = document.getElementById('watermark_' + index);
                if (WATERMARK_DIV) {
                    WATERMARK_DIV.style.height = '50%';
                }
            } else {
                PARENT.classList.add('w-100');
            }
        }
    }

    ngOnDestroy(): void {
        this.isLoading = false;
    }
}
