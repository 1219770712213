import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
    selector: 'app-selector-layout',
    templateUrl: './selector-layout.component.html',
    styleUrls: ['./selector-layout.component.scss']
})
export class SelectorLayoutComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,) { }

    ngOnInit(): void {
        this.authenticationService.login()
            .subscribe((response) => {
                if (response.data) {
                    this.authenticationService.userData.next(response.data);
                }
            });
    }

}
