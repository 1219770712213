import { Component, OnInit, NgZone, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { DateTimeService } from '../../services/date-time.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';
import { PdfGenerationService } from '../../services/pdf-generation.service';
import { EmailHelperService } from './email-helper.service';
import { FormControl, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PurchasePanelService } from 'src/app/core/services/purchase-panel.service';
import { ProjectInspirationService } from 'src/app/pages/project-inspiration/service/project-inspiration.service';

@Component({
    selector: 'app-saved-items',
    templateUrl: './saved-items.component.html',
    styleUrls: ['./saved-items.component.scss'],
    providers: [EmailHelperService]
})
export class SavedItemsComponent implements OnInit {

    date = '';
    @ViewChild('time') time: ElementRef;
    urlOnClose = '/project-inspiration';
    projects = [];
    panels = [];
    isShowPages = false;
    generatedPagesCount = 0;

    name: FormControl;
    email: FormControl;
    shade1: string;
    primaryColor: string;
    shade2: string;
    tint2: string;
    highlightColor: string;
    textColor: string;

    constructor(private _zone: NgZone,
                private _render: Renderer2,
                private _dateTimeService: DateTimeService,
                private _router: Router,
                private _localStorage: LocalStorageService,
                private _pdfService: PdfGenerationService,
                private _email: EmailHelperService,
                private _loaderService: LoaderService,
                private _purchaseView: PurchasePanelService,
                private _pInspiration: ProjectInspirationService) {
        this.name = new FormControl('', [Validators.required, Validators.maxLength(50)]);
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.date = this._dateTimeService.date;
        this._zone.runOutsideAngular(() => {
            setInterval(() => {
                this._render.setProperty(this.time.nativeElement, 'textContent', this._dateTimeService.getTime());
            });
        });
    }

    ngOnInit(): void {
        this.shade2 = this._localStorage.getItem('primaryShade2', 'localStorage') ? '#'+this._localStorage.getItem('primaryShade2', 'localStorage') : '#111417';
        this.primaryColor = this._localStorage.getItem('primaryColor', 'localStorage') ? '#'+this._localStorage.getItem('primaryColor', 'localStorage') : '#262c34';
        this.shade1 = this._localStorage.getItem('primaryShade1', 'localStorage') ? '#'+this._localStorage.getItem('primaryShade1', 'localStorage') : '#20262e';
        this.tint2 = this._localStorage.getItem('primaryTint2', 'localStorage') ? '#' + this._localStorage.getItem('primaryTint2', 'localStorage') : '#444a58';
        this.highlightColor = this._localStorage.getItem('highlightColor', 'localStorage') ? '#' + this._localStorage.getItem('highlightColor', 'localStorage') : '#df5844';
        this.textColor = this._localStorage.getItem('textColor', 'localStorage') ? '#' + this._localStorage.getItem('textColor', 'localStorage') : '#ffffff';
        document.documentElement.style.setProperty('--scroll-saved-background', this.primaryColor);
        document.documentElement.style.setProperty('--scroll-saved-highlight-color', this.tint2);
        document.documentElement.style.setProperty('--back-button-highlight-color', this.highlightColor);

        this.getSavedData();
        this.urlOnClose = this._localStorage.getItem('urlOnClose', 'localStorage');
        this.isShowPages = this.showPages();
    }

    close() {
        this._router.navigate([this.urlOnClose, { outlets: { saved: null } }]);
        this._pInspiration.saveClicked.next(null);
        this._purchaseView.savedPanels.next(false);
    }

    goHome() {
        this._router.navigate(['/selector']);
    }

    getSavedData() {
        this.getProjectsFromLocalStorage();
        this.getPanelsFromLocalStorage();
    }

    getProjectsFromLocalStorage() {
        const projectKeys = this._localStorage.getKeys().filter(key => {
            if (key.substring(0, 9) === 'project::') {
                return key;
            }
        });
        if (projectKeys.length > 0) {
            this.projects = [];
            projectKeys.forEach(key => {
                const project = JSON.parse(this._localStorage.getItem(key));
                project.image = project.image + '?' + Math.floor(Math.random() * 101);
                project.selected = true;
                project.key = key;
                this.projects.push(project);
            });
        }

    }

    getPanelsFromLocalStorage() {
        const panelKeys = this._localStorage.getKeys().filter(key => {
            if (key.substring(0, 7) === 'panel::') {
                return key;
            }
        });
        if (panelKeys.length > 0) {
            this.panels = [];
            panelKeys.forEach(key => {
                const panel = JSON.parse(this._localStorage.getItem(key));
                panel.selected = true;
                panel.key = key;
                panel.selectedStone.medium_image_url = panel.selectedStone.medium_image_url + '?' + Math.floor(Math.random() * 101);
                if (panel.selectedStone) {
                    panel.selectedStone.image_url = panel.selectedStone.image_url + '?' + Math.floor(Math.random() * 101);
                }
                if (panel.selectedSill) {
                    panel.selectedSill.image_url = panel.selectedSill.image_url + '?' + Math.floor(Math.random() * 101);
                }
                this.panels.push(panel);
            });
        }
    }

    showPages() {
        return (this.projects.length > 0 || this.panels.length > 0) ? true : false;
    }

    selectProject(index: number) {
        this.projects[index].selected = !this.projects[index].selected;
    }

    selectPanel(index: number) {
        this.panels[index].selected = !this.panels[index].selected;
    }

    get selectedItemsCount() {
        return ((this.projects.filter(project => project.selected === true)).length
            + (this.panels.filter(panel => panel.selected === true)).length);
    }

    deleteProject(project) {
        this._localStorage.clearItem(project.key);
        const deleteIndex = this.projects.indexOf(project);
        if (deleteIndex > -1) {
            this.projects.splice(deleteIndex, 1);
        }
        this.isShowPages  = this.showPages();
    }

    deletePanel(panel) {
        this._localStorage.clearItem(panel.key);
        const deleteIndex = this.panels.indexOf(panel);
        if (deleteIndex > -1) {
            this.panels.splice(deleteIndex, 1);
        }
        this.isShowPages  = this.showPages();
    }

    send() {
        this.name.markAsTouched();
        this.email.markAllAsTouched();

        if (this.name.errors || this.email.errors) {
            return;
        }
        if (this.selectedItemsCount > 0) {
            const projectsToSend = [];
            const panelsToSend = [];
            this.projects.forEach(project => {
                if (project.selected) {
                    const obj = {
                        project_id: project.id,
                        owner: project.owner,
                        stone_name: project.stone_name,
                        project_type: project.project_type,
                        image_url: project.image
                    };
                    projectsToSend.push(obj);
                }
            });
            this.panels.forEach(panel => {
                if (panel.selected) {
                    const obj = {
                        stone_id: panel.selectedStone.id,
                        stone_url: panel.selectedStone.medium_image_url,
                        mortar_name: panel.selectedMortar ? panel.selectedMortar.name : '',
                        mortar_hex: panel.selectedMortar ? panel.selectedMortar.hex_value : '20262e',
                        sill_name: panel.selectedSill ? panel.selectedSill.name : '',
                        sill_url: panel.selectedSill ? panel.selectedSill.image_url : ''
                    };
                    // obj.mortar_name = !obj.mortar_name && obj.mortar_hex ? obj.mortar_hex : '';
                    panelsToSend.push(obj);
                }
            });
            const payload = {
                name: this.name.value,
                email: this.email.value,
                projects: projectsToSend,
                stones: panelsToSend
            };
            this._email.sendEmailFromBE(payload);
        }
    }

    print() {
        if (this.selectedItemsCount > 0) {
            this._loaderService.showLoaderSubject.next(true);
            this._pdfService.printClicked.next(true);
        }
    }

    pdfCount(data) {
        const { print = true } = data;
        this.generatedPagesCount++;
        if (this.generatedPagesCount === this.selectedItemsCount) {
            this.generateFinalPdf(print);
            this.generatedPagesCount = 0;
        }
    }

    generateFinalPdf(print) {
        const projects = this.projects.filter(project => project.selected && project.pdfContent);
        const panels = this.panels.filter(panel => panel.selected && panel.pdfContent);
        const combinedSelections = [...projects, ...panels];
        if (print) {
            this.generatePrintPdf(combinedSelections);
        }
    }

    generatePrintPdf(selections) {
        const pdf = new jspdf('p', 'mm', 'a4');
        const position = 0;
        const imgWidth = 208;
        selections.forEach((selection, idx) => {
            this.appendPages(pdf, selection, idx, position, imgWidth);
        });
        this._loaderService.showLoaderSubject.next(false);
        if (pdf) {
            if (window.navigator.userAgent.indexOf('Trident') == -1) {
                window.open(pdf.output('bloburl'), '_blank');
            } else {
                pdf.save('Stonwerk_DesignCenter.pdf');
            }
        }
    }

    appendPages(pdf, item, idx, position, imgWidth) {
        if (item.pdfContent) {
            const {
                contentDataURL,
                height,
                width
            } = item.pdfContent;
            if (idx > 0) {
                pdf.addPage();
            }
            const imgHeight = height * imgWidth / width;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        }
    }

}
