<div class="wrapper" [ngStyle]="{'background-color': primaryColor}">
    <div class="header" [ngStyle]="{'background-color': primaryColor}">
        <div class="section-1"></div>
        <div class="smartphone-header" [ngStyle]="{'background-color': shade1}">
            <div class="logo" style="outline: none;">
                <div class="back-button"(click)="close()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 22">
                        <g>
                            <g transform="rotate(90 7 11)">
                                <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M-2.9 6.13v0l9.9 9.9v0l9.9-9.9v0" />
                            </g>
                        </g>
                    </svg>
                </div>
                <img src="assets/images/Stonelogo@s.png" class="logo-img" (click)="goHome()">
                <!-- <span class="design">DESIGN CENTER</span> -->
            </div>
            <div class="back">
                <span (click)="close()">Back</span>
            </div>
        </div>
        <div class="section-2">
            <span class="desc-1" [ngStyle]="{'color': textColor}" *ngIf="isShowPages">Please check pages you wish to email or print.</span>
            <span class="desc-2" [ngStyle]="{'color': textColor}" *ngIf="isShowPages">To delete a saved item press on the X button.</span>
            <span class="desc-1" [ngStyle]="{'color': textColor}" *ngIf="!isShowPages">There are no saved items.</span>
        </div>
        <app-alert></app-alert>
        <div class="section-3" [ngStyle]="{'background-color': shade2}">
            <div class="top-tile-1" (click)="close()">
                <span [ngStyle]="{'color': textColor}">Back</span>
            </div>
            <div class="top-tile-2">
                <span [ngStyle]="{'color': textColor}" #time style="text-transform: lowercase;"></span>
                <span [ngStyle]="{'color': textColor}" >{{date}}</span>

            </div>
        </div>
    </div>
    <div class="content">
        <ng-container *ngIf="isShowPages">
            <div class="saved-item-wrapper" *ngFor="let project of projects; let i = index">
                <div class="buttons">
                    <div class="tick" (click)="selectProject(i)" [ngStyle]="{'border-right': '1px solid ' + primaryColor, 'background-color': shade2}">
                        <svg [ngStyle]="{'stroke': project.selected?highlightColor:textColor, 'fill': project.selected?highlightColor:textColor}" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 22">
                            <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M1.8 9v0l5 5v0l13-13v0"/>
                        </svg>
                    </div>
                    <div class="cross" (click)="deleteProject(project)"[ngStyle]="{'border-left': '1px solid ' + primaryColor, 'background-color': shade2}">
                        <svg [ngStyle]="{'stroke': textColor, 'fill': textColor}" height="100%" width="100%" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
                        </svg>
                    </div>
                </div>
                <app-saved-projects style="width: 100%;height: 100%;" [project]="project"
                    (pdfGenerated)="pdfCount($event)"></app-saved-projects>
            </div>
            <div class="saved-item-wrapper" *ngFor="let panel of panels; let i = index">
                <div class="buttons">
                    <div class="tick" (click)="selectPanel(i)" [ngStyle]="{'border-right': '1px solid ' + primaryColor, 'background-color': shade2}">
                        <svg [ngStyle]="{'stroke': panel.selected?highlightColor:textColor, 'fill': panel.selected?highlightColor:textColor}" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 22">
                            <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M1.8 9v0l5 5v0l13-13v0"/>
                        </svg>
                    </div>
                    <div class="cross" (click)="deletePanel(panel)" [ngStyle]="{'border-left': '1px solid ' + primaryColor, 'background-color': shade2}">
                        <svg [ngStyle]="{'stroke': textColor, 'fill': textColor}" height="100%" width="100%" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
                        </svg>
                    </div>
                </div>
                <app-saved-panels style="width: 100%;height: 100%;" [panel]="panel" (pdfGenerated)="pdfCount($event)">
                </app-saved-panels>
            </div>
        </ng-container>
    </div>
    <div class="footer">
        <div class="section-1"></div>
        <div class="section-2">
            <div class="count-container" [ngStyle]="{'background-color': tint2}">
                <div class="count" [ngStyle]="{'color': highlightColor}">{{selectedItemsCount | number:'2.0'}} &nbsp;</div>
                <div class="description" [ngStyle]="{'color': textColor}">page(s) selected</div>
            </div>
            <div class="details" [ngStyle]="{'background-color': tint2}">
                <dl class="inputbox">
                    <dt class="inputbox-title" [ngStyle]="{'color': textColor}">Name*</dt>
                    <dd class="inputbox-content">
                        <input [ngClass]="{'error':name.errors && name.touched}" id="input0" type="text" [ngStyle]="{'color': textColor, 'border-bottom': '1px solid'+ textColor}"
                            [formControl]="name" />
                        <span *ngIf="name.touched && name.errors?.required" class="error-txt" [ngStyle]="{'color': highlightColor}">Please enter name</span>
                        <span *ngIf="name.touched && name.errors?.maxlength" class="error-txt" [ngStyle]="{'color': highlightColor}">Name is too long</span>
                        <label for="input0">Name</label>
                        <!-- <span class="underline"></span> -->
                    </dd>
                </dl>
                <dl class="inputbox">
                    <dt class="inputbox-title" [ngStyle]="{'color': textColor}">Email*</dt>
                    <dd class="inputbox-content">
                        <input id="input1" [ngClass]="{'error':email.errors && email.touched}" type="text" [ngStyle]="{'color': textColor, 'border-bottom': '1px solid'+ textColor}"
                            [formControl]="email" />
                        <span *ngIf="email.touched && email.errors?.required" class="error-txt" [ngStyle]="{'color': highlightColor}">Please enter email
                            id</span>
                        <span *ngIf="email.touched && email.errors?.email" class="error-txt" [ngStyle]="{'color': highlightColor}">Please enter a valid email
                            id</span>
                        <label for="input1">email@address.com</label>
                        <!-- <span class="underline"></span> -->
                    </dd>
                </dl>
            </div>
        </div>
        <div class="section-3" [ngStyle]="{'background-color': shade2}">
            <div class="bottom-tile" (click)="send()">
                <span [ngStyle]="{'color': textColor}">Send</span>
            </div>
            <div class="bottom-tile" (click)="print()">
                <span [ngStyle]="{'color': textColor}">Print</span>
            </div>
        </div>
    </div>
</div>
