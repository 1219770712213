import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import html2canvas from 'html2canvas';
import { PdfGenerationService } from '../../../services/pdf-generation.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
    selector: 'app-saved-projects',
    templateUrl: './saved-projects.component.html',
    styleUrls: ['./saved-projects.component.scss']
})
export class SavedProjectsComponent implements OnInit, OnDestroy {

    @Input() project;

    @ViewChild('projectPage') page: ElementRef;

    @Output() pdfGenerated = new EventEmitter<object>();
    printSub: Subscription;
    userData: any;
    logoUrl: any;

    constructor(private _pdfService: PdfGenerationService,
                private renderer: Renderer2,
                private authenticationService: AuthenticationService,) { }

    ngOnInit(): void {
        this.printSub = this._pdfService.printClicked.subscribe(res => {
            if (res) {
                this.generateContent(true);
            }
        });
        this.authenticationService.userData.subscribe(res => {
            if(res) { this.userData = res;
                this.logoUrl = this.userData.logo ? this.userData.logo : '../../../assets/images/Stonelogo@s.png';
            }
        });
    }

    ngOnDestroy() {
        this.printSub.unsubscribe();
        this._pdfService.printClicked.next(null);
    }

    generateContent(print) {
        if (this.project.selected) {
            const data = this.page.nativeElement;
            if (this.isMobile()) {
                this.renderer.setStyle(data, 'margin-top', '210px');
            }
            html2canvas(data, {
                scale: 3,
                allowTaint: true,
                useCORS: true,
                logging: false,
                removeContainer: true
            }).then(canvas => {
                // Few necessary setting options
                const contentDataURL = canvas.toDataURL('image/png');
                this.project.pdfContent = {
                    contentDataURL,
                    height: canvas.height,
                    width: canvas.width
                };
                if (this.isMobile()) {
                    this.renderer.removeStyle(data, 'margin-top');
                }
                this.pdfGenerated.emit({
                    print
                });
            });

        }
    }

    setWaterMarkStyle(id) {
        const ELEMENT = document.getElementById('projectImage_' + id);
        const WATERMARK = document.getElementById('watermarkDiv_' + id);
        if (ELEMENT && WATERMARK) {
            const HEIGHT = ELEMENT.clientHeight;
            const WIDTH = ELEMENT.clientWidth;
            if (HEIGHT > WIDTH) {
                WATERMARK.style.left = '5%';
            }
        }
    }

    isMobile() {
        return (window.innerWidth <= 767) ? true : false;
    }
}
