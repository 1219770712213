<div class="saved-item-page" #panelPage>
    <div class="page-header">
        <div class="inner-wrapper">
            <div class="logo-container">
                <img [src]="logoUrl">
            </div>
            <div class="name-container">
                <span>{{userData.address_line1}}.,</span>
                <span>{{userData.address_line2}}</span>
            </div>
        </div>
        <div class="header-underline"></div>
    </div>
    <div class="page-body">
        <div class="image-container">
            <div class="sill" *ngIf="panel?.selectedSill?.image_url">
                <img crossorigin="anonymous" [src]="panel?.selectedSill?.image_url" alt="">
            </div>
            <div class="stone">
                <div>
                    <img crossorigin="anonymous" [ngStyle]="getBG()"
                        [src]="panel?.selectedStone?.medium_image_url ? panel?.selectedStone?.medium_image_url : panel?.selectedStone?.image_url"
                        alt="">
                        <div class="watermark">
                            <img src="assets/images/watermark.png">
                        </div>
                </div>
            </div>
        </div>
        <div class="details-container">
            <span class="label">Stone</span>
            <span class="detail">{{panel?.selectedStone?.name ? panel?.selectedStone?.name : '-'}}</span>
        </div>
        <div class="details-container">
            <span class="label">Mortar</span>
            <span class="detail">{{panel?.selectedMortar?.name ? panel?.selectedMortar?.name : 'D3D3D3'}}</span>
        </div>
        <div class="details-container">
            <span class="label">Sill</span>
            <span class="detail">{{panel?.selectedSill?.name ? panel?.selectedSill?.name : '-'}}</span>
        </div>
        <div class="details-container">
            <span class="label">Information</span>
            <span class="detail">{{panel?.selectedStone?.information ? panel?.selectedStone?.information : '-'}}</span>
        </div>
        <div class="slider-container">
            <div class="slider">
                <span class="label">{{'Total'}}&nbsp;{{'cost'}}</span>
                <!-- <app-range [min]="0" [max]="10" [val]="panel?.selectedStone?.total_cost" [size]="'small'"></app-range> -->
                <img [src]="getSliderImageSrc(true,panel?.selectedStone?.total_cost)" style="width:100%">
            </div>
            <div class="slider">
                <span class="label" style="color: #D3D3D3;">{{'Labor'}}&nbsp;{{'cost'}}</span>
                <!-- <app-range [min]="0" [max]="10" [val]="panel?.selectedStone?.labor_cost" [size]="'small'" [inactive]="true"></app-range> -->
                <img [src]="getSliderImageSrc(false,panel?.selectedStone?.labor_cost)" style="width:100%">
            </div>
            <div class="slider">
                <span class="label" style="color: #D3D3D3;">{{'Material'}}&nbsp;{{'cost'}}</span>
                <!-- <app-range [min]="0" [max]="10" [val]="panel?.selectedStone?.material_cost" [size]="'small'" [inactive]="true"></app-range> -->
                <img [src]="getSliderImageSrc(false,panel?.selectedStone?.material_cost)" style="width:100%">
            </div>
        </div>

        <div class="details-container">
            <span class="label">Supplier/Region</span>
            <span class="detail">{{panel?.selectedStone?.supplier[0]?.supplier_name ? panel?.selectedStone?.supplier[0]?.supplier_name : '-'}}</span>
        </div>

    </div>
    <div class="page-footer" style="color: #D3D3D3;">
        Powered by the Stone Selector Tool: <a [href]="pdfSrc">www.stoneselectortool.com</a>
    </div>
    <div [ngClass]="{'inactive-overlay': !(panel?.selected)}"></div>
</div>
