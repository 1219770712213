<div class="saved-item-page" #projectPage>
    <div class="page-header">
        <div class="inner-wrapper">
            <div class="logo-container">
                <img [src]="logoUrl">
            </div>
            <div class="name-container">
                <span>{{userData.address_line1}}.,</span>
                <span>{{userData.address_line2}}</span>
            </div>
        </div>
        <div class="header-underline"></div>
    </div>
    <div class="page-body">
        <div class="image-container">
            <div class="image-wrapper">
                <img id="projectImage_{{project.id}}" crossorigin="anonymous" [src]="project?.image" (load)="setWaterMarkStyle(project.id)">
                <div class="watermark" id="watermarkDiv_{{project.id}}" *ngIf="project.owner == 'stonwerk'">                
                    <img src="assets/images/watermark.png">
                </div>
            </div>
        </div>
        <div class="details-container">
            <span class="label">Project</span>
            <span class="detail" *ngIf="project?.owner == 'stonwerk'">{{project?.name ? project?.name : '-'}}</span>
            <span class="detail" *ngIf="project?.owner != 'stonwerk'">Not by Stonwerk</span>
        </div>
        <div class="details-container" *ngIf="project?.project_type">
            <span class="label">{{'Project'}}&nbsp;{{'Type'}}</span>
            <span class="detail">{{project?.project_type ? project?.project_type : '-'}}</span>
        </div>
        <div class="details-container">
            <span class="label">Stone</span>
            <span class="detail">{{project?.stone_name ? project?.stone_name : '-'}}</span>
        </div>
        <div class="details-container">
            <span class="label">{{'Completed'}}&nbsp;{{'year'}}</span>
            <span class="detail" *ngIf="project?.completed_date">{{project?.completed_date | date:'yyyy'}}</span>
            <span class="detail" *ngIf="!project?.completed_date">-</span>
        </div>
    </div>
    <div [ngClass]="{'inactive-overlay': !(project?.selected)}"></div>
</div>
