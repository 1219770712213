import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPIClass } from '../classes/base-api-class';
import { LoaderService } from './loader.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    userData = new BehaviorSubject<any>('');
    constructor(private _http: BaseAPIClass,
                private _storageService: LocalStorageService,
                private _loaderService: LoaderService) { }

    login() {
       return this._http.post('users/login/').pipe(map((user) => {
        this._loaderService.showLoaderSubject.next(false);
        if (user.data) {
           this._storageService.setItem('access', user.data.token.access, 'localStorage');
           this._storageService.setItem('profileName', user.data.username, 'localStorage');
           this._storageService.setItem('primaryColor', user.data.theme_color.primary_color, 'localStorage');
           this._storageService.setItem('primaryTint1', user.data.theme_color.primary_tint_1, 'localStorage');
           this._storageService.setItem('primaryTint2', user.data.theme_color.primary_tint_2, 'localStorage');
           this._storageService.setItem('primaryShade1', user.data.theme_color.primary_shade_1, 'localStorage');
           this._storageService.setItem('primaryShade2', user.data.theme_color.primary_shade_2, 'localStorage');
           this._storageService.setItem('textColor', user.data.theme_color.text_color, 'localStorage');
           this._storageService.setItem('textShade1', user.data.theme_color.text_shade_1, 'localStorage');
           this._storageService.setItem('highlightColor', user.data.theme_color.highlight_color, 'localStorage');
           this._storageService.setItem('refresh', user.data.token.refresh);
           this.userData.next(user.data);
        }
        return user;
      }));
    }

    refreshToken() {
        return this._http.post(`api/token/refresh/`, {refresh:  this._storageService.getItem('refresh')});
    }

  getLicenceAgreement() {
    return this._http.getData('license/agreement/');
  }

  getThemeColors() {
    return this._http.getData('users/theme_color/');
  }
}
