<div class="carousel" id="carouselContainer">
    <ng-container *ngFor="let slide of slides; let i = index">
        <div id="loading" *ngIf="isLoading">
            <div class="fol">
                <div class="cir" id="c1"></div>
                <div class="cir" id="c2"></div>
                <div class="cir" id="c3"></div>
                <div class="cir" id="c4"></div>
            </div>
        </div>
        <div class="image-wrapper" *ngIf="(i === currentSlide)" id="imageWrapper"(click)="openImageOverlay()" >
            <img *ngIf="(i === currentSlide)" [src]="slide[imageField]" class="slide" id="image_{{i}}" @carouselAnimation
                (load)="hideLoader(i)" />
                <div id="watermark_{{i}}" class="watermark"  *ngIf="(i === currentSlide) && slide.owner == 'stonwerk'">                
                    <img src="assets/images/watermark.png" @carouselAnimation>
                </div>
        </div>
    </ng-container>

    <div class="enlarge-icon" (click)="openImageOverlay()">
        <svg [ngStyle]="{'stroke': textColor, 'fill': textColor}" height="100%" width="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" overflow="hidden">
            <defs><clipPath id="clip0"><rect x="943" y="324" width="96" height="96"/></clipPath></defs>
            <g clip-path="url(#clip0)" transform="translate(-943 -324)">
                <path d="M88 5 68.2 5C66.5431 5 65.2 6.34315 65.2 8 65.2 9.65685 66.5431 11 68.2 11L80.76 11 11 80.76 11 68.2C11 66.5431 9.65685 65.2 8 65.2 6.34315 65.2 5 66.5431 5 68.2L5 88C5 89.6569 6.34315 91 8 91L27.8 91C29.4569 91 30.8 89.6569 30.8 88 30.8 86.3431 29.4569 85 27.8 85L15.24 85 85 15.24 85 27.8C85 29.4569 86.3431 30.8 88 30.8 89.6569 30.8 91 29.4569 91 27.8L91 8C91 6.34315 89.6569 5 88 5Z" transform="matrix(-1 0 0 1 1039 324)"/>
            </g>
        </svg>
    </div>
</div>

<div id="image-modal" class="image-overlay" (click)="closeImageOverlay()">
    <div class="image-wrapper">
        <img id="overlay-content" class="image-overlay-content">
        <div class="watermark" id="watermarkDiv">                
            <img src="assets/images/watermark.png">
        </div>
    </div>
</div>
