import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProjects, IProjectSlide } from '../models/projects';
import { BaseAPIClass } from '../../../core/classes/base-api-class';

@Injectable()
export class ProjectInspirationService {

    currentSlide = new BehaviorSubject<number>(0);
    projectSlides = new BehaviorSubject<IProjectSlide[]>([]);
    tempProjectSlides: any = [];
    saveClicked = new BehaviorSubject<boolean>(null);
    printClicked = new BehaviorSubject<boolean>(null);
    isSimilarProjects = new BehaviorSubject<boolean>(false);
    similarProjectStoneId = null;
    projectInspirationFullScreen = new BehaviorSubject<boolean>(false);

    constructor(private _http: BaseAPIClass) { }

    slides = [];


    getProjectInspirationsByType(typeId: number) {
        this._http.get('project-showcase/projects/', { type_id: typeId }).subscribe(res => {
            this.projectSlides.next(this.formatSlide(res.data, typeId));
            this.tempProjectSlides = this.projectSlides.value;
            this.currentSlide.next(0);
        });
    }

    formatSlide(arr: IProjects[], typeId) {
        const result: IProjectSlide[] = [];
        arr.forEach(project => {
            if (project.images.length) {
                project.images.forEach((image: any) => {
                    const obj: IProjectSlide = {
                        id: project.id,
                        name: project.name,
                        completed_date: project.completed_date,
                        image: image.image_url,
                        stone_name: image.stone_name,
                        stone_id: image.stone_id,
                        stone_status: image.stone_status,
                        project_type: image.project_type,
                        owner: project.owner,
                        type_id: typeId
                    };
                    result.push(obj);
                });
            }
        });

        return result;
    }

    reset() {
        this.projectSlides.next(null);
        this.currentSlide.next(0);
    }

}
