import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PurchasePanelService } from 'src/app/core/services/purchase-panel.service';
import { SlideOutService } from '../../core/services/slide-out.service';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    leftSlideOutSub: Subscription;
    rightSlideOutSub: Subscription;
    isPurchasePanelViewSub: Subscription;
    isPurchasePanelSub: Subscription;

    leftSlideOutActive = false;
    rightSlideOutActive = false;
    isPurchasePanelView = false;
    isPurchasePanel = false;
    shade1: any;
    isSavedPanelClose: boolean;
    isSavedPanel: Subscription;

    constructor(private _slideOut: SlideOutService, private _purchaseView: PurchasePanelService,
                private _storageService: LocalStorageService) {
    }

    public innerWidth: any;

    isDesktop = true;
    isSmartPhone = false;
    isTablet = false;

    ngOnInit() {
        // tslint:disable-next-line:max-line-length
        this.shade1 = this._storageService.getItem('primaryShade1', 'localStorage') ? '#' + this._storageService.getItem('primaryShade1', 'localStorage') : '#20262e';
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 767) {
            this.isSmartPhone = true;
            this.isDesktop = false;
            this.isTablet = false;
        } else if (this.innerWidth <= 991) {
            this.isTablet = true;
            this.isSmartPhone = false;
            this.isDesktop = false;
        } else {
            this.isDesktop = true;
            this.isSmartPhone = false;
            this.isTablet = false;
        }
        this.slideOutSubscriptions();
        this.isNavigateToViewPurchase();
        this.isNavigateToPanelPurchase();
        this.isNavigateToStoneCreation();
    }
    ngAfterViewInit() {
    }

    slideOutSubscriptions() {
        this.leftSlideOutSub = this._slideOut.leftSlideOut.subscribe(res => {
            this.leftSlideOutActive = res;
        });
        this.rightSlideOutSub = this._slideOut.rightSlideOut.subscribe(res => {
            this.rightSlideOutActive = res;
        });
    }

    isNavigateToViewPurchase() {
        this.isPurchasePanelViewSub = this._purchaseView.purchaseView.subscribe(res => {
            this.isPurchasePanelView = res;
        });
    }

    isNavigateToPanelPurchase() {
        this.isPurchasePanelSub = this._purchaseView.isPurchase.subscribe(res => {
            this.isPurchasePanel = res;
        });
    }

    isNavigateToStoneCreation() {
        this.isSavedPanel = this._purchaseView.savedPanels.subscribe(res => {
            this.isSavedPanelClose = res;
        });
    }

    ngOnDestroy() {
        this.rightSlideOutSub.unsubscribe();
        this.leftSlideOutSub.unsubscribe();
        this.isPurchasePanelViewSub.unsubscribe();
        this.isPurchasePanelSub.unsubscribe();
    }

}
