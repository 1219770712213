import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAPIClass } from '../classes/base-api-class';
import { LoaderService } from './loader.service';

@Injectable({
    providedIn: 'root'
})
export class PurchasePanelService {

    purchaseView = new BehaviorSubject<boolean>(null);
    isPurchase = new BehaviorSubject<boolean>(null);
    isSimilarPojectsActive$ = new Subject();
    savedPanels = new BehaviorSubject<boolean>(true);

    constructor(private _http: BaseAPIClass,
                private _loaderService: LoaderService) { }

    getPurchasedPanels() {
        return this._http.get('payment/purchased_panels/');
    }

    getPanels(stoneId: any) {
        return this._http.get('sample-panel/panel/images/', { stone_id: stoneId })
        .pipe(map((response: any) => {
            this._loaderService.showLoaderSubject.next(false);
            if (response.status === 200) {
                return response;
            }
        }));
    }

    downloadHighQualityImage(payload: any) {
        return this._http.post('sample-panel/panel/images/download/', payload)
        .pipe(map((response: any) => {
            this._loaderService.showLoaderSubject.next(false);
            if (response.status === 200) {
                return response;
            }
        }));
    }

    checkPurchasedPanels(stoneId, sillId, mortarId, waterMark?) {
        return this._http.get('payment/check_purchased_panels/', { stone: stoneId, sill: sillId, mortar: mortarId, watermark: waterMark })
        .pipe(map((response: any) => {
            this._loaderService.showLoaderSubject.next(false);
            if (response.status === 200) {
                return response;
            } else {
              return response;
            }
        }));
    }

    purchasePanels(panelData) {
      return this._http.post('payment/panel_checkout/', panelData);
    }

    checkCreditStatus() {
      return this._http.post('payment/credits_available/', {});
    }

    getPanelId(param) {
        return this._http.post('payment/panel_creation/', param)
        .pipe(map((response: any) => {
            this._loaderService.showLoaderSubject.next(false);
            if (response.status === 200) {
                return response;
            } else {
              return response;
            }
        }));
    }
}
