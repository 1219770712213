// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://stonwerk-designcenteradmin.herokuapp.com/',
  mapApiKey: 'AIzaSyBstMnoiS--IrWTPjWTPeK0v1BxP3iIzbw',
  token: 'YWRtaW46c3RvbldlcmtAMTIz',
//   stripePublishableKey: 'pk_test_51KdT2JEgL9aqRtSwX4hlU8PWObzEoqLL6hMBtwDA49JR52DanBiyJxS7z5BrFCb6knLF7yDyS54BAMRDg4vQ2Dvz00YxM4lDFA', // for staging
  stripePublishableKey: 'pk_live_51KguLGEOUK8Id5r56ID2e9clC2EsZI12PJi6xmTBUAggBWsJJNs80x1VrAhNtF9ZZawXqfcEvjLwRUWQQdJeDPLA006sIY5RES',
  siteUrl:'https://www.stoneselectortool.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
