import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PdfGenerationService {

    printClicked = new BehaviorSubject<boolean>(null);
    // sendClicked = new BehaviorSubject<boolean>(null);

    constructor() { }

    generatePdf(htmlElement: HTMLElement) {
    }
}
