import { Component, OnInit, Input } from '@angular/core';
import { ConversionService } from 'src/app/core/services/conversion.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
    selector: 'app-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.scss']
})
export class RangeComponent implements OnInit {

    @Input() min = 0;

    @Input() max = 0;

    @Input() val = 0;

    @Input() size = 'normal';

    @Input() inactive = false;

    textColor: string;
    highlightColor: string;
    rgb: string;

    constructor( private _storageService: LocalStorageService,
                 private _conversionService: ConversionService) { }

    ngOnInit(): void {
        this.textColor = this._storageService.getItem('textColor', 'localStorage') ? '#' + this._storageService.getItem('textColor', 'localStorage') : '#FFFFFF';
        this.highlightColor = this._storageService.getItem('highlightColor', 'localStorage') ? '#' + this._storageService.getItem('highlightColor', 'localStorage') : '#df5844';
        this.rgb = this._conversionService.hexToRgb(this.highlightColor);
        document.documentElement.style.setProperty('--scroll-range-border-color', 'rgba(' + this.rgb + ',0.2)');
        document.documentElement.style.setProperty('--scroll-range-highlight-color', 'rgb(' + this.rgb + ')');
    }

    isSmall() {
        return this.size === 'small' ? true : false;
    }

    getSliderImageSrc(value) {
        return '../../../../../assets/images/cost-sliders-orange/' + Math.ceil(value) + '.png';
    }
}
