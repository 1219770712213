import { Injectable } from '@angular/core';
import { BaseAPIClass } from '../../../core/classes/base-api-class';
import { AlertService } from '../../services/alert.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class EmailHelperService {

    constructor(private _http: BaseAPIClass,
                private _alert: AlertService,
                private _loaderService: LoaderService,
                private _toastr: ToastrService) { }

    sendEMail(formData: FormData) {
        this._loaderService.showLoaderSubject.next(true);
        this._http.postFormData('mail/send', formData, true).subscribe(res => {
            this._loaderService.showLoaderSubject.next(false);
            this._alert.alert('Email sent successfully');
        });
    }

    sendEmailFromBE(payload) {
        this._loaderService.showLoaderSubject.next(true);
        this._http.post('mail/send-pdf-v2', payload, true).subscribe(res => {
            this._loaderService.showLoaderSubject.next(false);
            this._alert.alert(res.message);
        });
    }

    sendPanelId(payload) {
        this._loaderService.showLoaderSubject.next(true);
        this._http.post('mail/send-panel', payload, true).subscribe(res => {
            this._loaderService.showLoaderSubject.next(false);
            if(res.status == 200) {
              this._toastr.success(res.message);
            } else {
              this._toastr.error(res.message);
            }
        });
    }
}
