<div class="header-logo-container">
    <div class="back-button" (click)="back()">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 22">
            <g>
                <g transform="rotate(90 7 11)">
                    <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M-2.9 6.13v0l9.9 9.9v0l9.9-9.9v0" />
                </g>
            </g>
        </svg>
    </div>
    <div id="swLogo" (click)="back()">
        <img src="assets/images/LogoStonwerkDesignCenter@s.png" alt="Stonwerk">
    </div>
</div>