import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _authenticationService: AuthenticationService,
                private _localStorageService: LocalStorageService,
                private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                const REFRESH_TOKEN = this._localStorageService.getItem('refresh') ? this._localStorageService.getItem('refresh') : null;
                if (!request.url.includes('/api/token/') && REFRESH_TOKEN) {
                    return this._authenticationService.refreshToken().pipe(flatMap(response => {
                        if (response) {
                            this._localStorageService.setItem('access', response.access, 'localStorage');
                            const updatedReq = request.clone({
                                headers: request.headers.set('Authorization', 'Bearer ' + response.access)
                            });
                            return next.handle(updatedReq);
                        }
                    }));
                } else {
                    this._router.navigate(['/selector']);
                    this._localStorageService.clearItem('access', 'localStorage');
                    this._localStorageService.clearItem('refresh');
                }
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
