<div class="modal-container" [ngStyle]="{'background-color': tint2}">
  <div class="modal-body" [ngStyle]="{'color': textColor}">
    {{config.text}}
    <span [ngStyle]="{'color': highlightColor}">{{config.warning}}</span>
  </div>
  <div class="btn-grp">
    <div class="cancel-btn" (click)="cancelChanges()" [ngStyle]="{'background-color': tint1}">
      <span [ngStyle]="{'color': textColor}">{{config.yesText}}</span>
      <button type="submit" style="display: none;"></button>
    </div>
    <div class="cancel-btn" (click)="dontCancel()" [ngStyle]="{'background-color': tint1}">
      <span [ngStyle]="{'color': textColor}">{{config.noText}}</span>
      <button type="submit" style="display: none;"></button>
    </div>
  </div>
</div>