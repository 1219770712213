import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/helpers/auth.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SelectorLayoutComponent } from './selector-layout/selector-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/selector',
        pathMatch: 'full'
    },
    {
        path: 'selector',
        component: SelectorLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../pages/selector/selector.module').then(m => m.SelectorModule)
            }
        ],
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: 'project-inspiration',
                loadChildren: () => import('../pages/project-inspiration/project-inspiration.module').then(m => m.ProjectInspirationModule)
            },
            {
                path: 'stone-creation',
                loadChildren: () => import('../pages/stone-creation/stone-creation.module').then(m => m.StoneCreationModule)
            }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule { }
