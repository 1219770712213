import { Injectable, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    date: any;
    time: any;
    now: any;

    constructor(private zone: NgZone,
                private datePipe: DatePipe) {
        this.date = this.datePipe.transform(new Date(), 'MMM dd');
        this.zone.runOutsideAngular(() => {
            setInterval(() => {
                this.time = this.datePipe.transform(new Date(), 'hh:mm a');
            }, 1000);
        });
    }

    getTime() {
        return this.time;
    }
}
