<div class="logo-container" [ngClass]="{'home-container': isHomePage}" style="outline: none;"
*ngIf="(!isMobileView && !isTabView)">
    <div class="back-button" [ngClass]="{'home-back-btn': isHomePage}" *ngIf="!isUrlValid && !isLogin" (click)="back()">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 22" [ngStyle]="{'stroke': isSimilarProjects? highlightColor: textColor, 'fill': isSimilarProjects? highlightColor: textColor}">
            <g>
                <g transform="rotate(90 7 11)">
                    <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M-2.9 6.13v0l9.9 9.9v0l9.9-9.9v0" />
                </g>
            </g>
        </svg>
    </div>
    <!-- add harmburger button  -->
    <div class="back-button" [ngClass]="{'home-back-btn': isHomePage}" *ngIf="isUrlValid || showLogo">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 22"  [ngStyle]="{'stroke': isSimilarProjects? highlightColor: textColor, 'fill': isSimilarProjects? highlightColor: textColor}" (click)="loadAccountsSettings()" *ngIf="showbackBtn">
        <g>
            <g transform="rotate(90 7 11)">
                <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M-2.9 6.13v0l9.9 9.9v0l9.9-9.9v0" />
            </g>
        </g>
      </svg>
    </div>
    <div id="swLogo" (click)="goToSelector()" *ngIf="!isLogin || showLogo">
        <img [src]="logoUrl" alt="Stonwerk">
    </div>

</div>
<div class="logo-container tab-view" [ngClass]="{'tabview-margin': !showbackBtn && !isHomePage}" style="outline: none;" *ngIf="(isTabView && enableTabViewHeader) || isMobileView && enableTabViewHeader">
  <div class="back-button" *ngIf="showbackBtn">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 22" (click)="back()"  [ngStyle]="{'stroke': isSimilarProjects? highlightColor: textColor, 'fill': isSimilarProjects? highlightColor: textColor}">
      <g>
          <g transform="rotate(90 7 11)">
              <path fill="none" stroke-miterlimit="20" stroke-width="3" d="M-2.9 6.13v0l9.9 9.9v0l9.9-9.9v0" />
          </g>
      </g>
    </svg>
  </div>
  <div id="swLogo" (click)="goToSelector()">
    <img [src]="logoUrl" alt="Stonwerk">
    <!-- <span class="design">DESIGN CENTER</span> -->
  </div>
</div>
