<app-top-left-logo-container *ngIf="(!(leftSlideOutActive || rightSlideOutActive))"></app-top-left-logo-container>

<div class="wrapper" *ngIf="isDesktop">
    <div class="left-side-container" *ngIf="!isPurchasePanelView && !isPurchasePanel" [ngStyle]="{'background-color': shade1}">
        <router-outlet name="aside-left"></router-outlet>
    </div>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <div class="right-side-container" *ngIf="!isPurchasePanelView && !isPurchasePanel" [ngStyle]="{'background-color': shade1}">
        <router-outlet name="aside-right"></router-outlet>
    </div>
</div>

<div class="wrapper-smart-phone" *ngIf="isSmartPhone || isTablet">
    <div class="header">
        <router-outlet name="smart-phone-header"></router-outlet>
    </div>
    <div class="navbar" *ngIf="!isPurchasePanelView && !isPurchasePanel">
        <router-outlet name="smart-phone-navbar"></router-outlet>
    </div>
    <div class="left-slide-out" *ngIf="leftSlideOutActive" [ngStyle]="{'background-color': shade1}">
        <router-outlet name="aside-left"></router-outlet>
    </div>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <div class="right-slide-out" *ngIf="rightSlideOutActive && !isPurchasePanelView && !isPurchasePanel">
        <router-outlet name="aside-right"></router-outlet>
    </div>
    <div class="footer" *ngIf="!(leftSlideOutActive || rightSlideOutActive) && !isPurchasePanelView && !isPurchasePanel">
        <router-outlet name="smart-phone-footer"></router-outlet>
    </div>

</div>

<!-- <div class="wrapper-tablet" *ngIf="isTablet">

</div> -->

<router-outlet name='saved' *ngIf="!isPurchasePanelView && !isPurchasePanel && isSavedPanelClose"></router-outlet>

<app-copy-right-container class="copy-right" *ngIf="!isPurchasePanelView && !isPurchasePanel"></app-copy-right-container>
