import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
    selector: 'app-copy-right-container',
    templateUrl: './copy-right-container.component.html',
    styleUrls: ['./copy-right-container.component.scss']
})
export class CopyRightContainerComponent implements OnInit {

    show = false;
    textColor: string;
    constructor(private _storageService: LocalStorageService) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.show = true;
        }, 2000);
        this.textColor = this._storageService.getItem('textColor', 'localStorage') ? '#' + this._storageService.getItem('textColor', 'localStorage') : '#ffffff';
    }

}
