import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselComponent } from './carousel/carousel.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './services/alert.service';
import { SavedItemsComponent } from './pages/saved-items/saved-items.component';
import { SavedProjectsComponent } from './pages/saved-items/saved-projects/saved-projects.component';
import { SavedPanelsComponent } from './pages/saved-items/saved-panels/saved-panels.component';
import { RangeComponent } from './components/range/range.component';
import { PdfGenerationService } from './services/pdf-generation.service';
import { HeaderComponent } from './components/header/header.component';
import { TopLeftLogoContainerComponent } from './components/top-left-logo-container/top-left-logo-container.component';
import { MortarColorPickerComponent } from './components/mortar-color-picker/mortar-color-picker.component';
import { MortarService } from '../pages/stone-creation/service/mortar.service';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
    declarations: [
        CarouselComponent,
        AlertComponent,
        SavedItemsComponent,
        SavedProjectsComponent,
        SavedPanelsComponent,
        RangeComponent,
        HeaderComponent,
        TopLeftLogoContainerComponent,
        MortarColorPickerComponent,
        ConfirmationModalComponent
    ],
    imports: [
        CommonModule,
        Ng2GoogleChartsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        PdfViewerModule,
        ModalModule.forRoot(),
    ],
    exports: [
        CarouselComponent,
        Ng2GoogleChartsModule,
        AlertComponent,
        RangeComponent,
        SavedItemsComponent,
        HeaderComponent,
        TopLeftLogoContainerComponent,
        MortarColorPickerComponent,
        PdfViewerModule,
        ConfirmationModalComponent
    ],
    providers: [
        AlertService,
        MortarService,
        PdfGenerationService,
        BsModalRef
    ]
})
export class SharedModule { }
