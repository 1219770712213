import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PurchasePanelService } from 'src/app/core/services/purchase-panel.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
    selector: 'app-top-left-logo-container',
    templateUrl: './top-left-logo-container.component.html',
    styleUrls: ['./top-left-logo-container.component.scss']
})
export class TopLeftLogoContainerComponent implements OnInit {

    isMobileView = false;
    isTabView = false;
    isUrlValid = true;
    isLogin = false;
    pwdChange = false;
    showbackBtn = false;
    isHomePage = false;
    showLabel = false;
    showLogo = false;
    enableTabViewHeader = false;
    onAccountsSetings = false;
    showProfileMenu = false;
    userProfileInitial: string;
    logoUrl: string;
    primaryColor: string;
    tint1: string;
    tint2: string;
    textColor: string;
    hover: boolean;
    logoutHover: boolean;
    isSimilarProjects = false;
    highlightColor: string;

    @ViewChild('userProfile') profileToggleButton: ElementRef;
    @ViewChild('userProfileText') profileText: ElementRef;

    @ViewChild('profilePopover') profilePopOver: ElementRef;

    @ViewChild('tabViewHamburger') tabViewHamburger: ElementRef;
    @ViewChild('tabViewIconBar1') tabViewIconBar1: ElementRef;
    @ViewChild('tabViewIconBar2') tabViewIconBar2: ElementRef;
    @ViewChild('tabViewIconBar3') tabViewIconBar3: ElementRef;

    constructor(private router: Router,
                private _purchasePanelService: PurchasePanelService,
                private renderer: Renderer2,
                private _localStorage: LocalStorageService,
                private _authenticationService: AuthenticationService) {
      this.onClickOutSide();
    }

    ngOnInit(): void {
      this.logoUrl = 'assets/images/Stonelogo@s.png';
      this.tint1 = this._localStorage.getItem('primaryTint1', 'localStorage') ? '#' + this._localStorage.getItem('primaryTint1', 'localStorage') : '#2d333c';
      this.tint2 = this._localStorage.getItem('primaryTint2', 'localStorage') ? '#' + this._localStorage.getItem('primaryTint2', 'localStorage') : '#444a58';
      this.primaryColor = this._localStorage.getItem('primaryColor', 'localStorage') ? '#' + this._localStorage.getItem('primaryColor', 'localStorage') : '#262C34';
      this.textColor = this._localStorage.getItem('textColor', 'localStorage') ? '#' + this._localStorage.getItem('textColor', 'localStorage') : '#ffffff';
      this.highlightColor = this._localStorage.getItem('highlightColor', 'localStorage') ? '#' + this._localStorage.getItem('highlightColor', 'localStorage') : '#df5844';
      document.documentElement.style.setProperty('--back-button-highlight-color', this.highlightColor);

      if (this.router.url.includes('selector')) {
        if (this._localStorage.getItem('profileName', 'localStorage')) {
          this.userProfileInitial = (this._localStorage.getItem('profileName', 'localStorage'))[0].toUpperCase();
        } else {
          this.userProfileInitial = 'U';
        }
      }
      this.isMobileView = (window.innerWidth < 768) ? true : false;
      this.isTabView = (window.innerWidth <= 991 && window.innerWidth >= 768) ? true : false;
      this.isHomePage = this.router.url.includes('selector');
      this.isLogin = this.router.url.includes('login');
      this.pwdChange = this.router.url.includes('forgot-password');
      // tslint:disable-next-line:no-unused-expression
      !this.isLogin && !this.pwdChange && !this.router.url.includes('reset-password') && !this.router.url.includes('signup') && this.getUserDetails();
      // tslint:disable-next-line:max-line-length
      this.isUrlValid = this.router.url.includes('selector') || this.router.url.includes('signup') || this.router.url.includes('accounts-settings');
      // tslint:disable-next-line:max-line-length
      this.showbackBtn = this.router.url.includes('signup') || this.router.url.includes('accounts-settings') || this.router.url.includes('login;showLabel=true');
      this.onAccountsSetings = this.router.url.includes('accounts-settings');
      // tslint:disable-next-line:max-line-length
      this.enableTabViewHeader = this.router.url.includes('selector') || this.router.url.includes('signup') || this.router.url.includes('accounts-settings') || this.router.url.includes('login');
      this.showLogo = this.router.url.includes('login;showLabel=true');

      this._purchasePanelService.isSimilarPojectsActive$.subscribe(res => {
        if(res) this.isSimilarProjects = true;
        else this.isSimilarProjects = false;
      })
    }

    back() {
      if (this.router.url.includes('signup')) {
        this.router.navigate(['/login']);
      } else if (this.router.url.includes('purchase-panel')) {
        this.router.navigate(['/stone-creation']);
      } else {
        const URL = this._localStorage.getItem('urlOnClose','localStorage');
        this.router.url.includes('saved') ?
        this.router.navigate([URL, { outlets: { saved: null } }]) : this.router.navigate(['/selector']);
      }
    }

    loadAccountsSettings() {
      if (this.showbackBtn && !this.onAccountsSetings) {
        this.router.navigate(['/selector']);
      } else {
        const user = this._localStorage.getItem('access', 'localStorage');
        this.showLabel = true;
        if (user) {
          if (this.router.url.includes('selector')) { this.router.navigate(['/login', {showLabel: this.showLabel}]);
          } else { this.router.navigate(['/selector']); }
        } else {
          this.router.navigate(['/login', {showLabel: this.showLabel}]);
        }
      }
    }

    goToSelector() {
      this.router.navigate(['/selector']);
    }

    openProfilePopOver() {
      this.showProfileMenu = !this.showProfileMenu;
    }
    onClickOutSide() {
      this.renderer.listen('window', 'click', (e: Event) => {
        if (this.isTabView) {
          if (this.tabViewHamburger && this.tabViewIconBar1 && this.tabViewIconBar2 && this.tabViewIconBar3 && this.profilePopOver) {
            if (e.target !== this.profilePopOver.nativeElement && e.target !== this.tabViewHamburger.nativeElement
              && e.target !== this.tabViewIconBar1.nativeElement && e.target !== this.tabViewIconBar2.nativeElement && e.target !== this.tabViewIconBar3.nativeElement){
              this.showProfileMenu = false;
            }
          }
        } else {
          if (this.profileToggleButton && this.profileText && this.profilePopOver) {
            if (e.target !== this.profileToggleButton.nativeElement && e.target !== this.profileText.nativeElement && e.target !== this.profilePopOver.nativeElement){
              this.showProfileMenu = false;
            }
          }
        }

      });

    }
    logOut() {
      this._localStorage.clearItem('access', 'localStorage');
      this._localStorage.clearItem('highlightColor', 'localStorage');
      this._localStorage.clearItem('panel_id', 'localStorage');
      this._localStorage.clearItem('primaryColor', 'localStorage');
      this._localStorage.clearItem('primaryTint1', 'localStorage');
      this._localStorage.clearItem('primaryTint2', 'localStorage');
      this._localStorage.clearItem('primaryShade1', 'localStorage');
      this._localStorage.clearItem('primaryShade2', 'localStorage');
      this._localStorage.clearItem('profileName', 'localStorage');
      this._localStorage.clearItem('selectedStoneId', 'localStorage');
      this._localStorage.clearItem('selectedSillId', 'localStorage');
      this._localStorage.clearItem('selectedMortarColor', 'localStorage');
      this._localStorage.clearItem('selectedMortarName', 'localStorage');
      this._localStorage.clearItem('selectedSillUrl', 'localStorage');
      this._localStorage.clearItem('selectedTextureUrl', 'localStorage');
      this._localStorage.clearItem('selectedStoneUrl', 'localStorage');
      this._localStorage.clearItem('selectedSillName', 'localStorage');
      this._localStorage.clearItem('selectedMortarId', 'localStorage');
      this._localStorage.clearItem('textColor', 'localStorage');
      this._localStorage.clearItem('textShade1', 'localStorage');
      this._localStorage.clearItem('urlOnClose', 'localStorage');
      this._localStorage.clearItem('selectedStoneName', 'localStorage');
      this._localStorage.clearItem('information', 'localStorage');
      this._localStorage.clearItem('waterMark', 'localStorage');
      this._localStorage.clearItem('stateName', 'localStorage');
      this._localStorage.clearItem('previousStateName', 'localStorage');
      this._localStorage.clearAll();
      this.router.navigate(['/login']);
    }

    getUserDetails() {
      this._authenticationService.userData
      .subscribe((response) => {
          if (response) {
              const user = response;
              // tslint:disable-next-line:max-line-length
              this.logoUrl = user.permissions.includes('replace_logo') ? user.logo ? user.logo : 'assets/images/Stonelogo@s.png' : 'assets/images/Stonelogo@s.png';
          }
      });
  }

}
